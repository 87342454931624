@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:400,600|Miriam+Libre:400");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./Button";
@import "./Layout";
@import "./roundApplication";

/* lit modal */
#lcm-modal-overlay {
  z-index: 9999;
}

@layer base {
  html {
    font-family: Libre Franklin;
  }

  h1 {
    @apply text-7xl text-primary-text dark:text-primary-text;
    font-family: Miriam Libre;
  }

  h2 {
    @apply text-5xl text-primary-text dark:text-primary-text;
    font-family: Miriam Libre;
  }

  h3 {
    @apply text-3xl text-primary-text dark:text-primary-text font-extralight;
    font-family: Miriam Libre;
  }

  h4 {
    @apply text-2xl text-primary-text dark:text-primary-text font-extralight;
    font-family: Miriam Libre;
  }

  h5 {
    @apply text-base text-primary-text dark:text-primary-text font-extralight;
    font-family: Miriam Libre;
  }

  p {
    @apply text-secondary-text dark:text-primary-text font-thin;
  }

  div {
    font-family: Libre Franklin;
  }

  textarea {
    @apply appearance-none block border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none placeholder:text-sm;
  }

  input {
    @apply flex-1 block w-full rounded border border-tertiary-text py-2 px-3 focus:outline-none  placeholder:text-sm;
  }

  input.input-error {
    @apply border-2 border-gitcoin-pink-200;
  }

  textarea.input-error {
    @apply border-2 border-gitcoin-pink-200;
  }

  select.input-error {
    @apply border-2 border-gitcoin-pink-200;
  }

  div.input-error {
    @apply border-2 border-gitcoin-pink-200;
    border-radius: 3px;
    padding: 3px;
  }

  select {
    @apply mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border border-tertiary-text sm:text-sm rounded;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../assets/icons/chevron-down.svg");
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 98%;
    background-size: 30px 18px;
  }

  select:disabled {
    @apply bg-tertiary-text/30 border;
  }

  label {
    @apply text-sm mb-2;
    font-family: Libre Franklin;
  }

  legend {
    @apply text-secondary-text font-normal text-xs mb-2;
    font-family: Libre Franklin;
  }
}

.icon {
  display: inline-block;
}

.alerts-container {
  @apply mb-8 pt-4;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.alert {
  @apply flex items-center text-[14px] font-normal px-4 py-4 rounded relative mb-4;
}

.alert.error {
  @apply bg-red-100 text-red-500;
}

.alert.success {
  @apply bg-teal-100 text-teal-500;
}
